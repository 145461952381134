<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <ProductForm
      :product="editedProduct"
      :loading="$store.getters.loading[`put:api/product/${editedProduct.id}`]"
      :errors="productValidationErrors"
      @clear:errors="CLEAR_PRODUCT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import ProductForm from '@/components/forms/ProductForm';
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';

export default {
  name: 'EditProduct',

  components: { ProductForm },

  computed: {
    ...mapState('products', ['editedProduct', 'productValidationErrors']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('products/editProduct', +to.params.productId)
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          name: 'products',
        });
      });
  },

  methods: {
    ...mapActions('products', ['updateProduct']),
    ...mapMutations('products', ['CLEAR_PRODUCT_VALIDATION_ERRORS']),

    onSave(product) {
      this.updateProduct(product).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>

<style scoped></style>
